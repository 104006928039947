import styled from "styled-components"

export const Section = styled.section`
  padding: 0 5%;
  margin:5% 0;
  position:relative;
`

interface SpanDividerProps {
  fontSize?: string
}

export const SpanDivider = styled.span<SpanDividerProps>`
  font-size: ${props => props.fontSize  ? props.fontSize : 'calc(25px + (45 - 25) * ((100vw - 300px) / (1200 - 300)))' };
  @media screen and (min-width:1200px) {
    font-size: ${props => props.fontSize ? props.fontSize : '45px'};
  }
`

interface SectionTitleProps {
  fontSize?: string
}

export const SectionTitle = styled.h3<SectionTitleProps>`
  text-align: center;
  position:relative;
  z-index:100;
  text-transform:uppercase;
  font-size: ${({fontSize}) => fontSize ? fontSize : 'calc(21px + (32 - 21) * ((100vw - 300px) / (900 - 300)))'};
  @media screen and (min-width:1200px) {
    font-size:45px;
  }
`

export const PageTitle = styled.h1<SectionTitleProps>`
  text-align: center;
  position:relative;
  z-index:100;
  text-transform:uppercase;
  font-family: "Bankgothic Regular",serif;
  letter-spacing: 7px;
  font-weight:bold;
  font-size: ${({fontSize}) => fontSize ? fontSize : 'calc(17px + (45 - 17) * ((100vw - 300px) / (900 - 300)))'};
  @media screen and (min-width:900px) {
    font-size:45px;
    span {
      font-size: 70px;
    }
  }
`
